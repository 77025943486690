<template>
  <div class="container">

    <table-page dataName="list"
                :search="search"
                ref="table"
                :searchFun="searchFun"
                :tabList="tabList"
                :request="getEquityList">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$router.push('setKnightEquity')">添加权益</el-button>
      </template>

      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="ID">
      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="权益图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img_data ? scope.row.img_data.url :''" />
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="权益名称">
      </el-table-column>
      <el-table-column label="权益类型"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.type | typeName}}
        </template>
      </el-table-column>
      <el-table-column prop="institution_no"
                       show-overflow-tooltip
                       label="权益等级">
        <template slot-scope="scope">
          {{scope.row.knights_level === 1?'普通会员':'高级会员'}}
        </template>
      </el-table-column>
      <el-table-column prop="index"
                       show-overflow-tooltip
                       label="排序">
      </el-table-column>

      <el-table-column label="派发时间"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'NOW_PROCEED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>进行中
          </span>
          <span v-else-if="scope.row.status === 'NOT_PROCEED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$router.push({name:'KnightEquityRecord',query:{id:scope.row.id}})">查看数据</el-button>
          <el-button type="text"
                     @click="$router.push({name:'SetKnightEquity',query:{id:scope.row.id}})">编辑</el-button>
          <el-button type="text"
                     @click="delEquity(scope.row.id)">删除</el-button>

        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import TableImg from '@/components/TableImg'
import { getEquityList, delEquity } from '@/api/knight'
export default {
  name: 'Equity',
  data () {
    return {
      getEquityList,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }],
      search: [{
        type: 'input',
        name: '权益名称',
        placeholder: '请输入权益名称',
        key: 'name',
        value: ''
      }, {
        type: 'select',
        value: '',
        key: 'knights_level',
        name: '权益等级',
        list: [{
          value: 1,
          label: '普通会员'
        }, {
          value: 2,
          label: '高级会员'
        }]
      }, {
        type: 'select',
        value: '',
        key: 'type',
        name: '权益类型',
        list: [{
          value: 'COUPON',
          label: '代金券'
        }, {
          value: 'CARD_KEY',
          label: '卡密'
        }, {
          value: 'VERIFY',
          label: '网点核销'
        }]
      }, {
        type: 'input',
        name: '权益id',
        placeholder: '请输入权益id',
        key: 'id',
        value: ''
      }],
      searchParams: {}
    }
  },
  components: {
    TablePage,
    TableImg
  },
  filters: {
    typeName (type) {
      let name = ''
      switch (type) {
        case 'COUPON':
          name = '代金券'
          break
        case 'CARD_KEY':
          name = '卡密'
          break
        case 'VERIFY':
          name = '网点核销'
          break
        default:
          break
      }
      return name
    }
  },
  methods: {
    searchFun (res) {
      const param = Object.assign({}, res)
      return param
    },
    delEquity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delEquity({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
